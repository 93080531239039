.motion-container {
  width: 100%;
  height: 100%;
  display: flex;
  place-content: center;
  place-items: start;
  overflow: hidden;
}

.motion-item {
  display: grid;
  grid-template-rows: 70px;
  grid-auto-columns: 250px;
  grid-auto-rows: 250px;
  gap: 8px;
  cursor: grab;
}

.motion-item:active {
  cursor: grabbing;
}

.project-section {
  display: flex;
  align-items: center;
}

.project-section-title {
  z-index: 3;
  line-height: 100%;
  font-size: 2.1em;
  margin-bottom: 24px;
}

.project-section-card-container {
  z-index: 3;
  display: grid;
  grid-auto-columns: 100px;
  grid-auto-rows: 100px;
}

.card {
  transition: 0.3s;
  grid-row: 1/6;
  grid-column: 1/6;
  position: relative;
  border-radius: 10px;
  margin: 0px 24px 24px;
  overflow: hidden;
}

.card:hover {
  transition: 0.3s;
  filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.5));
}

.card:hover > .card-body {
  transition: 0.5s;
  background-color: #7743db;
}

.card-banner-image {
  transition: 0.3s;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20%;
}

.card-body {
  transition: 0.5s;
  background-color: #7d4edc7e;
  position: absolute;
  bottom: 0;
  height: 50%;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-decoration: none;
  padding: 0px 24px;
}

.card-date {
  font-size: 0.8em;
  line-height: 1em;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 24px;
  opacity: 0.8;
  letter-spacing: 1px;
}

.card-title {
  font-size: 2em;
  line-height: 1em;
  text-align: left;
  margin-bottom: 4px;
}

.card-description {
  transition: 0.3s;
  font-size: 1em;
  line-height: 1em;
  text-align: left;
  margin-bottom: auto;
  opacity: 0.8;
  letter-spacing: 1px;
}

.card-body-bottom-conainer {
  text-align: left;
}

.card-tech-text {
  transition: 0.3s;
  font-size: 0.8em;
  line-height: 1em;
  opacity: 0.8;
  letter-spacing: 1px;
}

.card-tech {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;
}

.card-link-img {
  margin: 0px 0px 0px auto;
}

.tech-list {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.tech-item {
  font-size: 1em;
  display: flex;
  align-items: center;
}

.project-item-tech-image {
  width: 1.7em;
  margin-right: 8px;
}

@media screen and (max-height: 727px) {
  .card {
    transition: 0.3s;
    grid-row: 1/5;
    border-radius: 10px;
    margin: 0px;
  }

  .card-body {
    transition: 1s;
    height: 60%;
    padding: 0px 16px;
  }

  .card-banner-image {
    transition: 0.3s;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0%;
  }
}

@media screen and (max-height: 643px) {
  .card {
    transition: 0.3s;
    grid-row: 1/4;
    border-radius: 10px;
    margin: 0px;
  }

  .card-body {
    transition: 1s;
    height: 100%;
    padding: 0px 16px;
  }

  .card-banner-image {
    transition: 0.3s;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0%;
  }
}

@media screen and (max-width: 500px) {
  .card {
    transition: 0.3s;
    grid-column: 1/5;
    border-radius: 10px;
    margin: 0px 8px 8px;
  }

  .card-body {
    transition: 0.5s;
    padding: 0px 16px;
  }

  .card-banner-image {
    transition: 0.3s;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30%;
  }
}

@media screen and (max-width: 425px) {
  .card {
    transition: 0.3s;
    grid-column: 1/4;
    border-radius: 10px;
    margin: 0px;
  }

  .card-banner-image {
    transition: 0.3s;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 45%;
  }
}
