.toast-container {
  transition: 0.5s;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
}

.active {
  transition: 0.5s;
  opacity: 1;
}

.toast {
  margin: 1.5em;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.1em;
  display: flex;
}

.toast-image {
  width: 1.1em;
  margin-right: 5px;
}
