.sections-container {
  position: relative;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100%;
  z-index: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.content-container {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1440px;
}

.content {
  transition: 0.3s;
  height: 100%;
  box-sizing: border-box;
  border-width: 0px;
  border-color: rgba(0, 0, 0, 0.281);
  border-style: dashed;
  padding-top: 117px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.decorative-rectengle {
  width: 100%;
  z-index: 3;
  user-select: none;
  -drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}

.nico-img {
  height: 80cqh;
  position: absolute;
}

.home-name-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  container-type: inline-size;
  position: relative;
  background: rgb(167, 219, 67);
  background: radial-gradient(
    circle,
    rgba(167, 219, 67, 1) 20%,
    rgba(0, 212, 255, 0) 40%
  );
}

.m-b-117 {
  margin-bottom: 117px !important;
}

.home-name {
  font-size: 30cqh;
  margin: 0px;
  padding: 0px;
  line-height: 80%;
  width: 100%;
  color: #7743db;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ticker1 {
  z-index: 2;
  animation: ticker 60s ease-in-out infinite;
}

.ticker2 {
  z-index: 0;
  animation: tickerReverse 60s ease-in-out infinite;
}

.z-i-2 {
  z-index: 2;
}

.scroll-down-img {
  margin-bottom: 16px;
  width: 15px;
}

@keyframes ticker {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  50% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes tickerReverse {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  50% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.me-section-card-container {
  z-index: 3;
  transition: 0.3s;
  display: flex;
  align-items: center;
  max-height: 150px;
  min-height: 150px;
  flex-direction: row;
  margin: 0px 24px;
  border-radius: 360px;
  position: relative;
}

.me-section-nico-img {
  transition: 0.3s;
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
  border-radius: 360px;
  margin: 0px;
  padding: 0px;
}

.me-section-card-text-container {
  transition: 0.3s;
  margin-top: 8px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 2px;
}

.me-section-name-text {
  display: flex;
  flex-direction: row;
  line-height: 100%;
  font-size: 30px;
}

.me-section-name-text h1 {
  margin: 0;
  padding: 0;
  padding-left: 5px;
  font-size: 30px;
  line-height: 100%;
}

.me-section-line-2 {
  font-size: 40px;
  line-height: 100%;
}

.me-section-lerner-text {
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  line-height: 100%;
  font-size: 60px;
}

.me-section-p-l-5 {
  padding-left: 5px;
}

.me-section-sized-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.me-section-tech-text {
  z-index: 3;
  margin: 24px auto 0px;
  line-height: 100%;
  font-size: 5cqh;
}

.me-section-then-tech-text {
  z-index: 3;
  margin: 24px;
  line-height: 100%;
  font-size: 3cqw;
  font-size: 3cqh;
}

.me-section-slider-container {
  transition: 0.3s;
  margin-top: 24px;
  min-height: 160px;
  width: 100%;
  position: relative;
}

.me-section-slider-container-front {
  z-index: 2;
  background-image: url("../../assets/main-color-deg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.me-section-slider-item-container {
  display: flex;
  align-items: center;
  height: 100%;
}
.me-section-slider-item {
  max-height: 61.5px;
  min-height: 61.5px;
  display: flex;
  margin: auto;
}

.me-section-slider-item-tech-image {
  max-width: 37.5px;
  pointer-events: none;
}

.me-section-slider-item-tech-only-image {
  width: 100%;
  pointer-events: none;
}

.me-section-slider-item-tech-name {
  font-size: 36px;
  margin-left: 5px;
}

.me-section-download-cv {
  transition: 0.3s;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 0px auto;
  padding: 0px 24px;
  border-radius: 10px;
  background-color: #7e4edc;
  font-size: 25px;
  white-space: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.me-section-download-cv:hover {
  transition: 0.3s;
  background-color: #c3acd0;
}

.me-section-download-cv:focus {
  transition: 0.3s;
  background-color: #c3acd0;
}

.me-section-download-arrow {
  margin-right: 4px;
}

.me-section-scroll-container {
  margin-top: auto;
}

.contact-section-info-container {
  z-index: 3;
  margin: 117px auto;
}
.contact-section-title {
  transition: 0.3s;
  font-size: 3.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contact-section-title-container {
  transition: 0.3s;
  display: flex;
}

.contact-section-item-container {
  transition: 0.3s;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.contact-section-item-text {
  transition: 0.3s;
  font-size: 2.5em;
  line-height: 90%;
  margin-left: 8px;
  user-select: text;
  color: #f5f9ff;
}

.contact-section-item-link {
  cursor: pointer;

  display: flex;
  align-items: center;
}

.contact-section-item-link-underline {
  border-bottom: solid 3px #a7db43;
  margin-right: 8px;
}

.contact-section-logos {
  transition: 0.3s;
  width: 1.5em;
}

.contact-section-copy-button {
  transition: 0.3s;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  width: 2em;
  box-sizing: border-box;
}

.contact-section-copy-button > img {
  width: 1.5em;
}

.contact-section-copy-button:hover {
  transition: 0.3s;
  background-color: #c3acd0;
}

.contact-section-copy-button:focus {
  transition: 0.3s;
  background-color: #c3acd0;
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 834px) {
  .me-section-card-container {
    transition: 0.3s;
    margin: 0px auto;
  }
}

@media screen and (max-width: 700px) {
  .home-name {
    font-size: 20cqh;
    margin: 0px;
    padding: 0px;
    line-height: 80%;
    width: 100%;
    color: #7743db;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ticker1 {
    z-index: 2;
    animation: ticker 20s ease-in-out infinite;
  }
  .ticker2 {
    z-index: 2;
    animation: tickerReverse 20s ease-in-out infinite;
  }
  .me-section-line-2 {
    transition: 0.3s;
    font-size: 1.8em;
  }
}

@media screen and (max-width: 600px) {
  .me-section-card-container {
    transition: 0.3s;
    margin: 0px 8px;
  }

  .me-section-card-text-container {
    transition: 0.3s;
    align-items: center;
    margin-right: 8px;
  }

  .me-section-nico-img {
    transition: 0.3s;
    min-width: 0px;
    min-height: 0px;
    max-width: 0px;
    max-height: 0px;
  }

  .me-section-line-2 {
    transition: 0.3s;
    font-size: 1.5em;
  }

  .me-section-tech-text {
    transition: 0.3s;
    font-size: 7cqw;
  }

  .me-section-then-tech-text {
    transition: 0.3s;
    font-size: 5cqw;
  }

  .contact-section-item-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .contact-section-item-text {
    transition: 0.3s;
    font-size: 1.5em;
  }
  .contact-section-logos {
    transition: 0.3s;
    width: 1.3em;
  }
  .contact-section-title {
    transition: 0.3s;
    font-size: 2.5em;
  }
}

@media screen and (max-width: 425px) {
  .me-section-lerner-text {
    transition: 0.3s;
    font-size: 15cqw;
  }

  .me-section-line-2 {
    transition: 0.3s;
    font-size: 5.5cqw;
  }
  .me-section-name-text {
    transition: 0.3s;
    font-size: 6cqw;
  }

  .me-section-name-text h1 {
    transition: 0.3s;
    font-size: 6cqw;
  }

  .contact-section-title {
    transition: 0.3s;
    font-size: 1.7em;
  }

  .contact-section-item-container {
    transition: 0.3s;
    margin-bottom: 8px;
  }

  .contact-section-item-text {
    transition: 0.3s;
    font-size: 1.3em;
  }

  .contact-section-copy-button {
    width: 1.7em;
  }

  .contact-section-copy-button > img {
    width: 1.3em;
  }
}

@media screen and (max-height: 834px) {
  .me-section-slider-container {
    transition: 0.3s;
    background-color: #7e4edc;
    margin-top: 24px;
    min-height: 120px;
    width: 100%;
    position: relative;
  }
}

@media screen and (max-height: 708px) {
  .me-section-slider-container {
    transition: 0.3s;
    margin-top: 16px;
    min-height: 80px;
  }

  .me-section-tech-text {
    margin: 16px auto 0px;
  }

  .me-section-then-tech-text {
    margin: 16px;
  }

  .me-section-lerner-text {
    transition: 0.3s;
    font-size: 45px;
  }

  .me-section-line-2 {
    transition: 0.3s;
    font-size: 20px;
  }
  .me-section-name-text {
    transition: 0.3s;
    font-size: 20px;
  }

  .me-section-name-text h1 {
    transition: 0.3s;
    font-size: 20px;
  }
}

@media screen and (max-height: 607px) {
  .me-section-card-container {
    transition: 0.3s;
    max-height: 125px;
    min-height: 125px;
    margin: 0px 16px;
    border-radius: 30px;
  }

  .me-section-nico-img {
    transition: 0.3s;
    min-width: 125px;
    min-height: 125px;
    max-width: 125px;
    max-height: 125px;
    border-radius: 30px;
  }
}

@media screen and (min-height: 834px) and (max-width: 600px) {
  .content {
    transition: 0.3s;
    padding-top: 175px;
  }

  .me-section-nico-img {
    transition: 0.3s;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
    position: absolute;
    bottom: 75%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .me-section-card-container {
    transition: 0.3s;
    max-height: 200px;
    min-height: 200px;
  }

  .me-section-card-text-container {
    transition: 0.3s;
    margin-top: 38px;
  }
}
