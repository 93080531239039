.section {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  scroll-snap-align: center;
  position: relative;
  overflow: hidden;
}
